// ChatBoxWrapper.js
import React, { useContext } from 'react';
import Chatbot from './Chatbot';
import './ChatBoxWrapper.css';
import { ChatBotContext } from './ChatBotContext';

const ChatboxWrapper = () => {
    const { isExpanded, setIsExpanded } = useContext(ChatBotContext);

    const toggleChatbox = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`chatbox-wrapper ${isExpanded ? 'expanded' : 'minimized'}`}>
            <div className="chatbox-header" onClick={toggleChatbox}>
                <span>{isExpanded ? 'Minimize' : 'Chat with Lisa 💬'}</span>
            </div>

            {isExpanded && (
                <div className="chatbox-content">
                    <Chatbot />
                </div>
            )}
        </div>
    );
};

export default ChatboxWrapper;
