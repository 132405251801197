import React from 'react';
import { Outlet } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';

import ServiceBusinessesList from './components/ServiceBusinessesList';
import ServiceGroupSubcategoryPage from './components/ServiceGroupSubcategoryPage';
import BusinessListView from './components/BusinessListView';
import ServiceTiles from './components/ServiceTiles';
import ViewToggle from './components/ViewToggle';
import BusinessView from './components/BusinessView';
import CitySearchModal from './components/CitySearchModal';
import LandingPageOld from './components/LandingPageOld';
import LandingPage from './components/LandingPage';
import ArticlePage from './components/ArticlePage';
import Layout from './components/Layout';
import RedirectSpaces from './components/RedirectSpaces';
import Chatbot from './components/Chatbot';

import { GoogleMapsProvider } from './contexts/GoogleMapsContext';
import { ChatBotProvider } from './components/ChatBotContext';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

console.log('Amplify Configuration:', awsconfig);

Amplify.configure(awsconfig);

const heroStyle = {
  backgroundImage: `url('/images/bali-landscape.jpg')`,
  /* Other styles... */
};

function slugify(text) {
  return text.replace(/%20/g, '-').replace(/\s+/g, '-').toLowerCase();
}

// function RedirectOldUrls() {
//   const { city, "*": rest } = useParams();

//   if (rest.startsWith('business')) {
//     // If the old URL is for a business
//     const businessName = rest.split('/')[1];
//     const slugifiedBusinessName = slugify(businessName);
//     return <Navigate to={`/${slugify(city)}/business/${slugifiedBusinessName}`} replace />;
//   } else {
//     // If the old URL is for other routes like services
//     return <Navigate to={`/${slugify(city)}/${rest}`} replace />;
//   }
// }

const App = () => {
  return (
    <GoogleMapsProvider>
      <ChatBotProvider>
        <Router>
          <RedirectSpaces />
          <div>
            {/* Navigation */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container"> {/* Updated to use Bootstrap container for consistent padding */}
                <Link className="navbar-brand" to="/">BioHack Hive</Link>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Services by City
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {[
                          "bali",
                          "san francisco",
                          "los angeles",
                          "austin",
                          "asheville",
                          "boulder",
                          "eugene",
                          "madison",
                          "charleston",
                          "ann arbor",
                          "burlington",
                          "santa fe",
                          "boise",
                          "dallas",
                          "new york",
                          "chicago",
                          "houston",
                          "phoenix",
                          "philadelphia",
                          "san antonio",
                          "san diego",
                          "san jose",
                          "jacksonville",
                          "fort worth",
                          "columbus",
                          "charlotte",
                          "indianapolis",
                          "seattle",
                          "denver",
                          "washington",
                          "boston",
                          "el paso",
                          "nashville",
                          "detroit",
                          "oklahoma city",
                          "portland",
                          "las vegas",
                          "memphis",
                          "louisville",
                          "baltimore",
                          "milwaukee",
                          "albuquerque",
                          "tucson",
                          "fresno",
                          "mesa",
                          "sacramento",
                          "atlanta"
                        ].map((city) => {
                          const slug = city.toLowerCase().replace(/\s+/g, '-');
                          return (
                            <li key={slug}>
                              <Link className="dropdown-item" to={`/${slug}/services`}>
                                {city.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>

                  {/* Search bar container */}
                  <div className="header-search d-none d-lg-block">
                    <CitySearchModal />
                  </div>
                </div>

              </div>

              {/* Separate search bar for mobile, placed below the navbar */}
              <div className="d-lg-none container mt-2"> {/* Used container class for consistent padding */}
                <CitySearchModal />
              </div>
            </nav>
            {/* Routes */}
            <div className="container mt-3">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/:city/*" element={<Layout />}>
                  <Route index element={<Navigate to="services" replace />} />
                  <Route path="services" element={<ServiceTiles />} />
                  <Route path="services/page/:page" element={<ServiceTiles />} />
                  <Route path="services/category/:categorySlug/page/:page" element={<ServiceTiles />} />
                  <Route path="services/category/:categorySlug" element={<ServiceTiles />} />

                  <Route path="businesses" element={<BusinessListView />} />
                  <Route path="businesses/page/:page" element={<BusinessListView />} />

                  <Route path="service/:serviceId" element={<ServiceBusinessesList />} />
                  <Route path="service/:serviceId/page/:page" element={<ServiceBusinessesList />} />

                  {/* New Route for Service Group Subcategory Page */}
                  <Route path="service/:serviceGroup/:serviceGroupSubcategory" element={<ServiceGroupSubcategoryPage />} />

                  <Route path="business/:businessSlug" element={<BusinessView />} />
                </Route>
                <Route path="/article" element={<ArticlePage />} />
                <Route path="/chat" element={<Chatbot />} />
                <Route path="*" element={<div>Not Found</div>} />
              </Routes>



            </div>
          </div>
        </Router>
      </ChatBotProvider>
    </GoogleMapsProvider>
  );
};

export default App;
