import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function RedirectSpaces() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;

    // Check if the URL contains spaces or %20
    if (path.includes(' ') || path.includes('%20')) {
      // Replace spaces and %20 with dashes
      const newPath = path.replace(/[\s%20]+/g, '-');

      // Redirect to the new URL
      navigate(newPath, { replace: true });
    }
  }, [location, navigate]);

  // Only add the canonical tag if the URL does not contain spaces or %20
  const isCleanUrl = !location.pathname.includes(' ') && !location.pathname.includes('%20');

  return (
    isCleanUrl && (
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}${location.pathname}`} />
      </Helmet>
    )
  );
}

export default RedirectSpaces;
