// ChatBotContext.js
import React, { createContext, useState } from 'react';

export const ChatBotContext = createContext();

export const ChatBotProvider = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const value = {
        isExpanded,
        setIsExpanded,
    };

    return (
        <ChatBotContext.Provider value={value}>
            {children}
        </ChatBotContext.Provider>
    );
};
