import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './ServiceBusinessesList.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function capitalizeWords(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

const reverseSlug = (slug) => {
    return slug.replace(/-/g, ' ');
};

const createSlug = (businessName) => {
    return businessName.toLowerCase().replace(/\s+/g, '-');
};

const scrollToSubcategory = (subcategoryId) => {
    const element = document.getElementById(subcategoryId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

const ServiceBusinessesList = () => {
    const { city, serviceId } = useParams();
    const serviceName = serviceId.replace(/-/g, ' ').toLowerCase();
    const cityDisplayName = capitalizeWords(reverseSlug(city));
    const serviceDisplayName = capitalizeWords(reverseSlug(serviceId));

    const [subcategories, setSubcategories] = useState({});
    const [loading, setLoading] = useState(true);
    const [description, setDescription] = useState('Loading...');

    useEffect(() => {
        const fetchSubcategories = async () => {
            try {
                const response = await fetch(`https://zxjxolehed.execute-api.ap-southeast-1.amazonaws.com/dev/data?cityName=${encodeURIComponent(city)}&serviceSlug=${encodeURIComponent(serviceId)}`);
                const data = await response.json();

                setSubcategories(data.subcategories || {});
                setDescription(data.description);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching subcategories:', error);
                setLoading(false);
            }
        };

        fetchSubcategories();
    }, [city, serviceId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const renderStars = (rating) => {
        if (typeof rating !== 'number' || rating < 0 || rating > 5) {
            return Array(5).fill(<span className="text-muted">★</span>);
        }

        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {Array(fullStars).fill(<span className="text-warning">★</span>)}
                {halfStar && <span className="text-warning">★</span>}
                {Array(emptyStars).fill(<span className="text-light">★</span>)}
            </>
        );
    };

    return (
        <div className="service-businesses-list-container">
            <Helmet>
                <title>{`Top ${serviceDisplayName || 'Services'} in ${cityDisplayName} | Best ${serviceDisplayName} Providers - BioHack Hive`}</title>
                <meta
                    name="description"
                    content={`Explore the top-rated ${serviceDisplayName || 'biohacking'} services in ${cityDisplayName}. Find the best providers for ${serviceDisplayName} including specialized subcategories like ${Object.keys(subcategories).slice(0, 2).join(', ')}. Read reviews, compare services, and book your session today.`}
                />
            </Helmet>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        <Link to={`/${city}/services`} itemProp="item">
                            <span itemProp="name">Services</span>
                        </Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li className="breadcrumb-item active" aria-current="page" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        <span itemProp="name">{serviceDisplayName}</span>
                        <meta itemProp="position" content="2" />
                    </li>
                </ol>
            </nav>


            <h2 className="mb-4">Best {serviceDisplayName || 'Service'} in {cityDisplayName}</h2>

            {/* Placeholder for Service Group Description */}
            <div className="service-group-description mb-4">
                <p>
                    {description}
                </p>
            </div>

            {/* Quick Jump Navigation */}
            <div className="quick-jump-nav mb-4">
                {Object.keys(subcategories).map((subcategory, index) => (
                    <a
                        key={index}
                        href={`#subcategory-${index}`}
                        className="quick-jump-link"
                        onClick={() => scrollToSubcategory(`subcategory-${index}`)}
                    >
                        
                        {subcategory == 'General' ? subcategory + ' ' + serviceDisplayName : subcategory}
                    </a>
                ))}
            </div>

            {/* Display subcategories and associated businesses/services */}
            {Object.keys(subcategories).map((subcategory, index) => (
                <div key={index} id={`subcategory-${index}`} className="subcategory-section mb-5">
                    <h3 className="subcategory-title">
                    {subcategory == 'General' ? subcategory + ' ' + serviceDisplayName : subcategory} Services in {cityDisplayName} ({subcategories[subcategory].businessCount})
                        <Link
                            to={`/${city}/service/${serviceId}/${subcategories[subcategory].slug}`}
                            className="btn-view-subcategory ml-3"
                            role="button"
                        >
                            View All {subcategory == 'General' ? subcategory + ' ' + serviceDisplayName : subcategory} Providers
                        </Link>
                    </h3>
                    <Carousel responsive={responsive} infinite={false} className="subcategory-carousel">
                        {subcategories[subcategory].businesses.map((business, idx) => (
                            <div key={idx} className="card business-card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">{business.businessName}</h5>
                                    <p className="card-text rating">
                                        <span className="stars">{renderStars(business.googlePlaces?.rating)}</span>
                                        <span className="rating-text">({business.googlePlaces?.rating || 'N/A'}) - {business.googlePlaces?.userRatingCount || '0'} Reviews</span>
                                    </p>
                                    <div className="card-text services-list">
                                        <strong>Services:</strong>
                                        <ul>
                                            {business.services.slice(0, 3).map((service, idx) => (
                                                <li key={idx}>{service.serviceName}</li>
                                            ))}
                                            {business.services.length > 3 && (
                                                <li>+ {business.services.length - 3} more</li>
                                            )}
                                        </ul>
                                    </div>
                                    <p className="card-text address">{business.googlePlaces?.formattedAddress || 'Location not available'}</p>
                                    <div className="card-text contact">
                                        <span>📞 {business.googlePlaces?.internationalPhoneNumber || 'Phone not available'}</span>
                                        <span>🌐 <a href={business.websiteUrl} target="_blank" rel="noopener noreferrer">Website</a></span>
                                    </div>
                                    <a
                                        href={`/${city}/business/${createSlug(business.businessName)}`}
                                        className="btn-business btn-primary-business mt-3"
                                        role="button"
                                    >
                                        View Business
                                    </a>
                                </div>
                            </div>
                        ))}
                        {/* Add "View All" card if there are more than 10 businesses */}
                        {/*subcategories[subcategory].businessCount > 10*/ true && (
                            <div className="card view-all-card h-100">
                                <div className="card-body d-flex justify-content-center align-items-center">
                                    <Link
                                        to={`/${city}/service/${serviceId}/${subcategories[subcategory].slug}`}
                                        className="btn btn-outline-primary btn-lg"
                                        role="button"
                                    >
                                        View All {subcategory}
                                    </Link>
                                </div>
                            </div>
                        )}
                    </Carousel>
                </div>
            ))}


        </div>
    );
};

export default ServiceBusinessesList;
