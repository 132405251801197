import React from 'react';
import { Helmet } from "react-helmet-async";

import './LandingPage.css';
import CitySearchModal from './CitySearchModal';

const LandingPage = () => {
    return (
        <div className="landing-page">

            <Helmet>
                <title>Biohack Hive - Your Ultimate Health & Wellness Directory | Discover, Connect, Thrive</title>
                <meta name="description" content="Explore Biohack Hive, the leading directory for health and wellness services. Find top experts, wellness centers, and holistic treatments to enhance your well-being." />
                <meta name="keywords" content="health, wellness, directory, biohacking, holistic health, well-being, wellness services, wellness experts" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.biohackhive.com/" />
            </Helmet>

            {/* Hero Section */}
            <section className="hero-section text-center">
                <div className="hero-content">
                    <h1 className="display-4">Discover the Best in Health and Wellness Worldwide</h1>
                    <p className="lead">Explore and compare top health and wellness services tailored to your needs.</p>
                    <div className="mt-4">
                        <CitySearchModal />
                    </div>
                </div>
            </section>

            {/* Benefits Section */}
            <section className="benefits-section container py-5">
                <div className="row text-center">
                    <div className="col-md-4 mb-4">
                        <i className="bi bi-globe"></i> {/* Use an icon for global reach */}
                        <h3>Global Reach</h3>
                        <p>Find health and wellness businesses across the globe.</p>
                    </div>
                    <div className="col-md-4 mb-4">
                        <i className="bi bi-person-check"></i> {/* Use an icon for expert services */}
                        <h3>Expert-Verified Listings</h3>
                        <p>Access services recommended by industry experts.</p>
                    </div>
                    <div className="col-md-4 mb-4">
                        <i className="bi bi-columns-gap"></i> {/* Use an icon for comparing */}
                        <h3>Compare and Choose</h3>
                        <p>Compare services and make informed decisions.</p>
                    </div>
                </div>
            </section>


            {/* Numbers Section */}
            <section className="numbers-section py-5 text-center">
                <h2>Our Growing Impact</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <i className="bi bi-list-check icon-large"></i> {/* Icon for Services Listed */}
                            <h3 className="text-primary">345,532+</h3>
                            <p>Services Listed</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <i className="bi bi-building icon-large"></i> {/* Icon for Businesses */}
                            <h3 className="text-primary">24,000+</h3>
                            <p>Businesses</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <i className="bi bi-geo-alt icon-large"></i> {/* Icon for Cities Covered */}
                            <h3 className="text-primary">492+</h3>
                            <p>Cities Covered</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* Social Proof Section */}
            <section className="social-proof-section py-5 text-center">
                <h2>What Our Users Say</h2>
                <div className="testimonial-slider">
                    <div className="testimonial mb-4">
                        <div className="stars">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                        </div>
                        <p>"BioHack Hive made it easy for me to find and compare top wellness services in my area. I've never felt better!"</p>
                        <h4>Emily R.</h4>
                    </div>
                    <div className="testimonial mb-4">
                        <div className="stars">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                        </div>
                        <p>"I discovered incredible health services I didn’t know existed. My wellness journey has taken off thanks to BioHack Hive!"</p>
                        <h4>Michael T.</h4>
                    </div>
                    <div className="testimonial mb-4">
                        <div className="stars">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                        </div>
                        <p>"A one-stop shop for all my health and wellness needs. I highly recommend BioHack Hive to anyone serious about their well-being."</p>
                        <h4>Sarah K.</h4>
                    </div>
                </div>
            </section>

            {/* How It Works Section */}
            <section className="how-it-works-section py-5 text-center">
                <h2>How It Works</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <i className="bi bi-search icon-large"></i> {/* Icon for Find Services */}
                            <h3>1. Find Services</h3>
                            <p>Explore a wide range of health and wellness services worldwide, all in one place.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <i className="bi bi-columns-gap icon-large"></i> {/* Icon for Compare Providers */}
                            <h3>2. Compare Providers</h3>
                            <p>Review and compare different services to find the best fit for your health and wellness needs.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <i className="bi bi-award icon-large"></i> {/* Icon for Elevate Your Well-Being */}
                            <h3>3. Elevate Your Well-Being</h3>
                            <p>Connect with top providers and take actionable steps towards achieving your wellness goals.</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* FAQ Section */}
            <section className="faq-section container py-5">
                <h2 className="text-center">Frequently Asked Questions</h2>
                <div className="accordion" id="faqAccordion">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                How does BioHack Hive work?
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                BioHack Hive connects you with the best health and wellness services globally, helping you discover and compare options tailored to your needs.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What services can I find on BioHack Hive?
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                BioHack Hive offers a wide range of health and wellness services, including but not limited to IV therapy, cryotherapy, fitness training, nutrition counseling, and more.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Is BioHack Hive available in my city?
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                BioHack Hive is available in over 492 cities worldwide, with more being added regularly. Use our search feature to find services near you.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                How do I get started?
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                Simply use our search bar to find services in your city, compare your options, and connect with the providers that best suit your needs.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Final CTA Section */}
            <section className="final-cta-section text-center py-5">
                <h2>Ready to Elevate Your Health and Wellness?</h2>
                {/* <button className="cta-button mt-3">Join Now</button>
                <p>No credit card required. Cancel anytime.</p> */}
            </section>
        </div>
    );
};

export default LandingPage;
