import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { GoogleMapsContext } from '../contexts/GoogleMapsContext';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import './BusinessListView.css';

function capitalizeWords(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

const reverseSlug = (slug) => slug.replace(/-/g, ' ');
const createSlug = (businessName) => businessName.toLowerCase().replace(/\s+/g, '-');

const BusinessListView = () => {
    const { city, page = 1 } = useParams(); // Extract the city name and page number from the route
    const cityDisplayName = capitalizeWords(reverseSlug(city));
    const { isLoaded } = useContext(GoogleMapsContext);

    const [businesses, setBusinesses] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [hoveredBusiness, setHoveredBusiness] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: -8.749324339537052, lng: 115.18559053178676 });
    const [mapZoom, setMapZoom] = useState(12);
    const [totalPages, setTotalPages] = useState(1);
    const [totalBusinesses, setTotalBusinesses] = useState(255);
    const mapRef = useRef(null);

    const pageSize = 12; // Set the page size (number of businesses per page)
    const currentPage = parseInt(page, 10); // Convert the page from the URL to a number

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
        borderRadius: '8px',
        marginBottom: '20px',
    };

    const fetchBusinesses = async (page) => {
        try {
            const response = await fetch(`https://zxjxolehed.execute-api.ap-southeast-1.amazonaws.com/dev/data?data=businesslist&cityName=${city}&page=${page}&pageSize=${pageSize}`);
            const data = await response.json();

            setBusinesses(data.businesses);
            setTotalPages(data.totalPages);
            setTotalBusinesses(data.totalBusinesses);

            if (data.businesses.length > 0 && mapRef.current) {
                const bounds = new window.google.maps.LatLngBounds();
                data.businesses.forEach(business => {
                    if (business.googlePlaces && business.googlePlaces.location) {
                        const position = {
                            lat: business.googlePlaces.location.latitude,
                            lng: business.googlePlaces.location.longitude,
                        };
                        bounds.extend(position);
                    }
                });

                if (!bounds.isEmpty()) {
                    mapRef.current.fitBounds(bounds);
                    const mapCenter = bounds.getCenter();
                    const mapZoom = mapRef.current.getZoom();

                    setMapCenter({ lat: mapCenter.lat(), lng: mapCenter.lng() });
                    setMapZoom(mapZoom);
                }
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    useEffect(() => {
        fetchBusinesses(currentPage);
    }, [city, currentPage]);

    const markers = businesses.map(business => {
        const hasLocation = business.googlePlaces && business.googlePlaces.location;

        return {
            position: hasLocation ? {
                lat: business.googlePlaces.location.latitude,
                lng: business.googlePlaces.location.longitude,
            } : null,
            title: business.businessName,
            businessData: business,
        };
    });

    const calculateMapBounds = () => {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(marker => {
            if (marker.position) {
                bounds.extend(marker.position);
            }
        });
        return bounds;
    };

    const handleMapLoad = (map) => {
        mapRef.current = map;
    };

    const handleMarkerClick = (business) => {
        if (!business.googlePlaces) return;
        setSelectedMarker(business);
        if (mapRef.current) {
            const { latitude, longitude } = business.googlePlaces.location;
            mapRef.current.panTo({ lat: latitude, lng: longitude });
            setMapCenter({ lat: latitude, lng: longitude });
        }
    };

    const handleMouseOver = (business) => {
        if (!business.googlePlaces) return;
        setHoveredBusiness(business);
    };

    const handleMouseOut = () => {
        setHoveredBusiness(null);
    };

    const renderStars = (rating) => {
        if (typeof rating !== 'number' || rating < 0 || rating > 5) {
            return Array(5).fill(<span className="text-muted">★</span>);
        }

        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {Array(fullStars).fill(<span className="text-warning">★</span>)}
                {halfStar && <span className="text-warning">★</span>}
                {Array(emptyStars).fill(<span className="text-light">★</span>)}
            </>
        );
    };

    const generatePageNumbers = (totalPages, currentPage) => {
        const maxPagesToShow = 3;
        let pages = [];
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push('...');
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push('...');
            }
            pages.push(totalPages);
        }

        return pages;
    };

    const pages = generatePageNumbers(totalPages, currentPage);

    return (
        <div className="business-list-view-container">
            <Helmet>
                <title>Top Biohacking & Wellness Businesses in {cityDisplayName} - BioHack Hive</title>
                <meta
                    name="description"
                    content={`Explore the best biohacking and wellness businesses in ${cityDisplayName}. Find top-rated providers, read reviews, check out locations, and discover a wide range of services to enhance your health and well-being.`}
                />
                <meta
                    name="keywords"
                    content={`biohacking businesses in ${cityDisplayName}, wellness services ${cityDisplayName}, top biohacking providers, health services ${cityDisplayName}, ${cityDisplayName} wellness`}
                />
            </Helmet>

            <h2 className="mb-4">Explore {totalBusinesses} Wellness Businesses in {cityDisplayName}</h2>

            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={mapCenter}
                    zoom={mapZoom}
                    onLoad={map => (mapRef.current = map)}
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                    }}
                >
                    {markers.map((marker, index) => (
                        marker.position && (
                            <MarkerF
                                key={index}
                                position={marker.position}
                                title={marker.title}
                                onClick={() => handleMarkerClick(businesses[index])}
                            />
                        )
                    ))}
                    {selectedMarker && (
                        <InfoWindowF
                            position={{
                                lat: selectedMarker.googlePlaces.location.latitude,
                                lng: selectedMarker.googlePlaces.location.longitude,
                            }}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div>
                                <h4>{selectedMarker.businessName}</h4>
                                {selectedMarker.googlePlaces && (
                                    <>
                                        <p>{renderStars(selectedMarker.googlePlaces.rating)} ({selectedMarker.googlePlaces.userRatingCount || 0} Reviews)</p>
                                        <p><strong>Services:</strong></p>
                                        <ul>
                                            {selectedMarker.services.slice(0, 3).map((service, idx) => (
                                                <li key={idx}>{service.name}</li>
                                            ))}
                                            {selectedMarker.services.length > 3 && (
                                                <li>+ {selectedMarker.services.length - 3} more</li>
                                            )}
                                        </ul>
                                        <p><strong>Address:</strong> {selectedMarker.googlePlaces.formattedAddress || 'Address not available'}</p>
                                        <p>📞 {selectedMarker.googlePlaces.internationalPhoneNumber || 'Phone number not available'}</p>
                                        <p>🌐 <a href={selectedMarker.websiteURL} target="_blank" rel="noopener noreferrer">Visit Website</a></p>
                                    </>
                                )}
                            </div>
                        </InfoWindowF>
                    )}
                    {hoveredBusiness && !selectedMarker && hoveredBusiness.googlePlaces && (
                        <InfoWindowF
                            position={{
                                lat: hoveredBusiness.googlePlaces.location.latitude,
                                lng: hoveredBusiness.googlePlaces.location.longitude,
                            }}
                        >
                            <div>
                                <h4>{hoveredBusiness.businessName}</h4>
                                <p>{renderStars(hoveredBusiness.googlePlaces.rating)} ({hoveredBusiness.googlePlaces.userRatingCount || 0} Reviews)</p>
                                <p><strong>Services:</strong></p>
                                <ul>
                                    {hoveredBusiness.services.slice(0, 3).map((service, idx) => (
                                        <li key={idx}>{service.name}</li>
                                    ))}
                                    {hoveredBusiness.services.length > 3 && (
                                        <li>+ {hoveredBusiness.services.length - 3} more</li>
                                    )}
                                </ul>
                                <p><strong>Address:</strong> {hoveredBusiness.googlePlaces.formattedAddress || 'Address not available'}</p>
                                <p>📞 {hoveredBusiness.googlePlaces.internationalPhoneNumber || 'Phone number not available'}</p>
                                <p>🌐 <a href={hoveredBusiness.websiteURL} target="_blank" rel="noopener noreferrer">Visit Website</a></p>
                            </div>
                        </InfoWindowF>
                    )}
                </GoogleMap>
            )}

            <div className="business-list-grid">
                {businesses.map((business, idx) => (
                    <Link
                        key={idx}
                        to={`/${city}/business/${encodeURIComponent(createSlug(business.businessName))}`}
                        className="col-md-4 mb-4 text-decoration-none"
                        onMouseOver={() => setHoveredBusiness(business)}
                        onMouseOut={() => setHoveredBusiness(null)}
                    >
                        <div className="card business-card h-100">
                            <div className="card-body">
                                <h5 className="card-title text-dark">
                                    {business.businessName}
                                </h5>
                                <p className="card-text">
                                    <small className="text-muted">{business.googlePlaces?.formattedAddress || 'Location not available'}</small>
                                </p>
                                {business.googlePlaces && (
                                    <div className="card-text">
                                        <span className="text-warning">
                                            {renderStars(business.googlePlaces.rating)}
                                        </span>
                                        <span className="rating-text">
                                            ({business.googlePlaces.rating || 'N/A'} / 5) - {business.googlePlaces?.userRatingCount || '0'} Reviews
                                        </span>
                                    </div>
                                )}

                                <p className="card-text">{business.description || 'A short description of the business goes here.'}</p>
                                <p className="card-text">
                                    <strong>Services: </strong>
                                    {business.services.slice(0, 3).map(service => service.name).join(', ')}
                                    {business.services.length > 3 && (
                                        <span className="text-muted"> + {business.services.length - 3} more</span>
                                    )}
                                </p>
                                <p className="card-text">
                                    📞 {business.googlePlaces?.internationalPhoneNumber || 'Phone Number not available'}
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>

            <div className="pagination-container text-center mt-4">
                {currentPage > 1 ? (
                    <Link
                        to={`/${city}/businesses/page/${currentPage - 1}`}
                        className="btn btn-secondary"
                    >
                        Previous
                    </Link>
                ) : (
                    <span className="btn btn-secondary" disabled>
                        Previous
                    </span>
                )}

                {pages.map((page, index) =>
                    typeof page === 'number' ? (
                        page === currentPage ? (
                            <span key={index} className="current-page page-number" disabled>
                                {page}
                            </span>
                        ) : (
                            <Link
                                key={index}
                                to={`/${city}/businesses/page/${page}`}
                                className="page-number"
                            >
                                {page}
                            </Link>
                        )
                    ) : (
                        <span key={index} className="ellipsis" disabled>
                            {page}
                        </span>
                    )
                )}

                {currentPage < totalPages ? (
                    <Link
                        to={`/${city}/businesses/page/${currentPage + 1}`}
                        className="btn btn-secondary ml-2"
                    >
                        Next
                    </Link>
                ) : (
                    <span className="btn btn-secondary ml-2" disabled>
                        Next
                    </span>
                )}
            </div>
        </div>
    );
};

export default BusinessListView;
