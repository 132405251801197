import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import debounce from 'lodash/debounce';
import './ServiceTile.css';

function capitalizeWords(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

const createSlug = (businessName) => {
    return businessName.toLowerCase().replace(/\s+/g, '-');
};

const reverseSlug = (slug) => {
    return slug.replace(/-/g, ' ');
};

const categories = [
    { id: 1, name: 'Longevity & Anti-Aging', slug: 'longevity-anti-aging' },
    { id: 2, name: 'Performance & Optimization', slug: 'performance-optimization' },
    { id: 3, name: 'Beauty & Aesthetics', slug: 'beauty-aesthetics' },
    { id: 4, name: 'Wellness & Preventative Care', slug: 'wellness-preventative-care' },
    { id: 5, name: 'Recovery & Rehabilitation', slug: 'recovery-rehabilitation' },
    { id: 6, name: 'Detoxification & Cleansing', slug: 'detoxification-cleansing' },
    { id: 7, name: 'Mental Health & Relaxation', slug: 'mental-health-relaxation' },
    { id: 8, name: 'Biohacking & Advanced Health', slug: 'biohacking-advanced-health' },
    { id: 9, name: 'Nutrition & Diet', slug: 'nutrition-diet' },
    { id: 10, name: 'Fitness & Physical Training', slug: 'fitness-physical-training' },
    { id: 11, name: 'Spiritual & Holistic Health', slug: 'spiritual-holistic-health' }
];

const ServiceTiles = () => {
    const { city, page, categorySlug } = useParams();
    const cityDisplayName = capitalizeWords(reverseSlug(city));
    const navigate = useNavigate();

    const [services, setServices] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [totalServices, setTotalServices] = useState(654);
    const [selectedCategory, setSelectedCategory] = useState(
        categorySlug ? categories.find(category => category.slug === categorySlug)?.id : ''
    );

    const servicesPerPage = 21;
    const currentPage = parseInt(page, 10) || 1;

    // Debounced function to handle search
    const debouncedFetchServices = useCallback(
        debounce(async (city, currentPage, searchTerm, selectedCategory) => {
            setLoading(true);
            try {
                const queryParam = searchTerm ? `&q=${encodeURIComponent(searchTerm)}` : '';
                if (searchTerm) {
                    currentPage = 1; // reset to page 1 for a search.
                }
                const categoryParam = selectedCategory ? `&categoryId=${selectedCategory}` : '';
                const response = await fetch(`https://zxjxolehed.execute-api.ap-southeast-1.amazonaws.com/dev/data?data=service&cityName=${city}&page=${currentPage}&pageSize=${servicesPerPage}${queryParam}${categoryParam}`);
                const data = await response.json();

                setServices(data.services);
                setTotalPages(data.totalPages);
                setTotalServices(data.totalServices);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching services:', error);
                setLoading(false);
            }
        }, 300), // 300ms debounce delay
        []
    );

    // Effect to fetch services when city, page, searchTerm, or selectedCategory changes
    useEffect(() => {
        debouncedFetchServices(city, currentPage, searchTerm, selectedCategory);
    }, [city, currentPage, searchTerm, selectedCategory, debouncedFetchServices]);

    const handlePageChange = (newPage) => {
        const searchParam = searchTerm ? `?q=${encodeURIComponent(searchTerm)}` : '';
        navigate(`/${city}/services/category/${categorySlug}/page/${newPage}${searchParam}`);
    };

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const generatePageNumbers = (totalPages, currentPage) => {
        const maxPagesToShow = 3;
        let pages = [];
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push('...');
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push('...');
            }
            pages.push(totalPages);
        }

        return pages;
    };

    const pages = generatePageNumbers(totalPages, currentPage);

    return (
        <div className="service-tiles-container">
            <Helmet>
                <title>
                    {categorySlug
                        ? `Top ${totalServices} ${capitalizeWords(categorySlug.replace('-', ' '))} Services in ${cityDisplayName} | Book Now at BioHack Hive`
                        : `Explore ${totalServices} Health & Wellness Services in ${cityDisplayName} | Book Now at BioHack Hive`}
                </title>
                <meta
                    name="description"
                    content={
                        categorySlug
                            ? `Explore the top ${capitalizeWords(categorySlug.replace('-', ' '))} services in ${cityDisplayName} at BioHack Hive. Discover highly-rated providers offering ${categorySlug.replace('-', ' ')} treatments. Book your session today and enhance your well-being.`
                            : `Discover the best health and wellness services in ${cityDisplayName} at BioHack Hive. Explore top-rated providers offering a wide range of treatments. Book your session today and enhance your well-being.`
                    }
                />
            </Helmet>
            <h2>
                {categorySlug
                    ? `Explore ${totalServices}+ ${capitalizeWords(categorySlug.replace('-', ' '))} Services in ${cityDisplayName}`
                    : `Explore ${totalServices}+ Health & Wellness Services in ${cityDisplayName}`}
            </h2>

            {/* Category Tabs */}
            <div className="category-tabs-container">
                <div className="category-tabs">
                    <Link
                        to={`/${city}/services`}
                        className={`category-tab ${!selectedCategory ? 'active' : ''}`}
                        onClick={() => setSelectedCategory('')}
                    >
                        All
                    </Link>
                    {categories.map((category) => (
                        <Link
                            key={category.id}
                            to={`/${city}/services/category/${category.slug}`}
                            className={`category-tab ${selectedCategory === category.id ? 'active' : ''}`}
                            onClick={() => setSelectedCategory(category.id)}
                        >
                            {category.name}
                        </Link>
                    ))}
                </div>
            </div>

            <div className="search-bar-container mb-4">
                <input
                    type="text"
                    placeholder="Search for a service..."
                    className="form-control"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
            </div>

            {loading ? (
                <div className="loading-container">Loading...</div>
            ) : (
                <div className="row">
                    {services.map(service => (
                        <div key={service.name} className="col-md-4 mb-4">
                            <div className="card service-tile">
                                <div className="card-body">
                                    <h5 className="card-title">{service.serviceNameNormalized} ({service.uniqueBusinessCount})</h5>
                                    <p className="card-text">
                                        {service.description || 'Description of the service goes here.'}
                                    </p>

                                    <div className="business-list">
                                        {service.businesses.slice(0, 3).map((business, index) => (
                                            <div key={index} className="business-item">
                                                <span className="business-icon">🏢</span>
                                                <Link to={`/${city}/business/${encodeURIComponent(createSlug(business.businessName))}`}>
                                                    {business.businessName}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>

                                    {service.uniqueBusinessCount > 3 && (
                                        <p className="text-muted mt-2">+ {service.uniqueBusinessCount - 3} more businesses</p>
                                    )}

                                    <Link to={`/${city}/service/${createSlug(service.serviceNameNormalized)}`} className="btn btn-primary mt-3">
                                        {service.uniqueBusinessCount > 3 ? 'View All Providers' : 'View Providers'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {!searchTerm && (
                <div className="pagination-container text-center mt-4">
                    {currentPage > 1 ? (
                        <Link
                            to={`/${city}/services/${selectedCategory ? `category/${categorySlug}/` : ''}page/${currentPage - 1}`}
                            className="btn btn-secondary"
                        >
                            Previous
                        </Link>
                    ) : (
                        <span className="btn btn-secondary" disabled>
                            Previous
                        </span>
                    )}

                    {pages.map((page, index) =>
                        typeof page === 'number' ? (
                            page === currentPage ? (
                                <span key={index} className="current-page page-number" disabled>
                                    {page}
                                </span>
                            ) : (
                                <Link
                                    key={index}
                                    to={`/${city}/services/${selectedCategory ? `category/${categorySlug}/` : ''}page/${page}`}
                                    className="page-number"
                                >
                                    {page}
                                </Link>
                            )
                        ) : (
                            <span key={index} className="ellipsis" disabled>
                                {page}
                            </span>
                        )
                    )}

                    {currentPage < totalPages ? (
                        <Link
                            to={`/${city}/services/${selectedCategory ? `category/${categorySlug}/` : ''}page/${currentPage + 1}`}
                            className="btn btn-secondary ml-2"
                        >
                            Next
                        </Link>
                    ) : (
                        <span className="btn btn-secondary ml-2" disabled>
                            Next
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default ServiceTiles;
