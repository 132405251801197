import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import './Chatbot.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ChatBotContext } from './ChatBotContext';
import { v4 as uuidv4 } from 'uuid';

// Default quick reply options
const defaultQuickReplies = [
    "I'm not sure, please suggest something.",
    "I know what I want—help me find it.",
    "Show me popular wellness services near me.",
];

const createSlug = (businessName) => {
    return businessName.toLowerCase().replace(/\s+/g, '-');
};

const Chatbot = () => {
    const { city } = useParams();
    const [messages, setMessages] = useState([]); // For display purposes
    const [conversationHistory, setConversationHistory] = useState([]); // For full conversation context, including tags
    const { isExpanded } = useContext(ChatBotContext);
    const [userInput, setUserInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [quickReplies, setQuickReplies] = useState(defaultQuickReplies); // Track quick replies dynamically

    const [conversationId, setConversationId] = useState(null);

    useEffect(() => {
        if (!conversationId) {
            const newConversationId = uuidv4();
            setConversationId(newConversationId);  // Set the conversation ID
        }
    }, [conversationId]);

    // Now conversationId will remain constant for this session
    console.log('Conversation ID:', conversationId);

    const smallBarMessages = [
        "Hi! I'm here to help you find the perfect wellness service.",
        "Looking for something specific or just exploring options?",
        "How can I assist you on your wellness journey today?",
        "Need recommendations? I'm happy to guide you!",
        "Let's find the right service to fit your wellness goals."
    ];

    const [messageIndex, setMessageIndex] = useState(0); // Track the current message index
    const [displayedText, setDisplayedText] = useState(''); // Text being typed
    const wordIndexRef = useRef(0); // Track the word index across renders
    const currentSentenceRef = useRef(''); // Track the sentence being typed

    useEffect(() => {
        const currentMessage = smallBarMessages[messageIndex]; // Get the current message string
        const words = currentMessage.split(' '); // Split the current message into an array of words

        // Reset the displayed text and the word/sentence references
        setDisplayedText('');
        wordIndexRef.current = 0;
        currentSentenceRef.current = '';

        // Start the typing interval
        const typingInterval = setInterval(() => {
            if (wordIndexRef.current < words.length) {
                // Append the next word to the sentence being typed
                currentSentenceRef.current += words[wordIndexRef.current] + (wordIndexRef.current < words.length - 1 ? ' ' : '');
                // Update the displayed text with the sentence being typed
                setDisplayedText(currentSentenceRef.current);
                wordIndexRef.current++; // Move to the next word
            } else {
                // All words are typed, stop the interval
                clearInterval(typingInterval);

                // Wait for 3 seconds and then rotate to the next message
                setTimeout(() => {
                    setMessageIndex((prevIndex) => (prevIndex + 1) % smallBarMessages.length); // Move to the next message
                }, 10000); // 3-second delay after full message is displayed
            }
        }, 80); // Typing speed: 500ms per word (you can adjust this as needed)

        // Cleanup the interval when the component unmounts or message changes
        return () => clearInterval(typingInterval);
    }, [messageIndex]);

    useEffect(() => {
        if (isExpanded && messages.length === 0) {
            // Trigger typing effect or initial messages when chat is expanded
            displayTypingEffect('Hi there! Welcome to our wellness directory. What are you looking for today?', true);
        }
    }, [isExpanded]);

    const handleUserInput = (event) => {
        setUserInput(event.target.value);
    };

    // Handle sending messages via the Enter key
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage(userInput); // Pass userInput as a parameter
        }
    };

    // Function to handle quick reply clicks
    const handleQuickReply = (text) => {
        handleSendMessage(text); // Send the quick reply text directly
    };

    const handleSendMessage = async (message) => {
        if (message.trim() === '') return;

        const newMessages = [...messages, { role: 'user', content: message }];
        setMessages(newMessages);
        setUserInput('');

        const newConversationHistory = [
            ...conversationHistory,
            { role: 'user', content: message } // Full message with tags for LLM
        ];

        setConversationHistory(newConversationHistory);

        // Call backend API and display the typing indicator
        setIsTyping(true);

        await handleBotResponse(newConversationHistory); // Pass all messages to maintain conversation history
    };

    const handleBotResponse = async (newConversationHistory) => {
        setIsWaitingForResponse(true);
        try {
            const response = await fetch(`https://3otz5feh7e.execute-api.ap-southeast-1.amazonaws.com/dev/data?data=chat&cityName=${city}&conversationId=${conversationId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ messages: newConversationHistory }),  // Send the full conversation history (with tags) to the LLM
            });
            const result = await response.json();
            let botResponse = result.response;
            const businessData = result.businessData;

            setIsWaitingForResponse(false);

            if (botResponse) {
                // Display the bot response with typing effect (text will be stripped of tags for display)
                await new Promise((resolve) => {
                    displayTypingEffect(botResponse, false, resolve);
                });

                // Parse and update quick reply options
                const newQuickReplies = parseQuickReplyOptions(botResponse);  // Parse the original response with tags
                if (newQuickReplies.length > 0) {
                    setQuickReplies(newQuickReplies); // Update quick replies if found
                } else {
                    setQuickReplies(defaultQuickReplies); // Fall back to default quick replies
                }
            }

            if (businessData) {
                const businessMessage = { role: 'mock', content: renderBusinessCards(businessData) };
                setMessages((prevMessages) => [...prevMessages, businessMessage]);
            }

            setIsTyping(false);
        } catch (error) {
            setIsWaitingForResponse(false);
            console.error('Error fetching bot response:', error);
        }
    };


    // Function to parse quick reply options from the bot response
    const parseQuickReplyOptions = (response) => {
        const quickReplyRegex = /<QuickReplyOption text="(.*?)">/g;
        let matches;
        const quickReplies = [];

        // Use regex to find all matches for <QuickReplyOption> tags
        while ((matches = quickReplyRegex.exec(response)) !== null) {
            quickReplies.push(matches[1]); // Extract the text from the tag
        }

        return quickReplies;
    };

    const displayTypingEffect = (text, isInitial = false, callback = () => { }) => {
        let index = 0;
        let displayText = '';

        // Strip <QuickReplyOption> tags for display
        const displayTextWithoutTags = text.replace(/<QuickReplyOption text=".*?">/g, '');

        const words = displayTextWithoutTags.split(' ');

        // Step 1: Add an empty placeholder for the assistant message (for display only)
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, { role: 'assistant', content: '...' }];
            return updatedMessages;
        });

        const typingInterval = setInterval(() => {
            setMessages((prevMessages) => {
                let messageIndex = prevMessages.length - 1; // The index of the message being typed

                if (index < words.length) {
                    displayText += words[index] + ' ';
                    index++;

                    // Update the display message at the specific index with the typing effect (display only)
                    const updatedMessages = [...prevMessages];
                    updatedMessages[messageIndex] = { role: 'assistant', content: displayText + '...' };
                    return updatedMessages;
                } else {
                    clearInterval(typingInterval);
                    setIsTyping(false);

                    // Final display message (without tags for display purposes)
                    const updatedMessages = [...prevMessages];
                    updatedMessages[messageIndex] = { role: 'assistant', content: displayTextWithoutTags.trim() };
                    callback();  // Notify that the typing effect is done

                    // Add the full message (with tags) to the conversation history
                    setConversationHistory((prevHistory) => [
                        ...prevHistory,
                        { role: 'assistant', content: text } // Full message with tags for LLM
                    ]);

                    return updatedMessages;
                }
            });

            scrollToBottom(); // Ensure scrolling to the bottom after each word update
        }, isInitial ? 50 : 50); // Adjust typing speed as needed
    };


    // Reference to the messages container
    const messagesEndRef = useRef(null);

    // Scroll to the bottom when a new message is added
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    // Call scrollToBottom after the messages are updated
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const renderStars = (rating) => {
        if (typeof rating !== 'number' || rating < 0 || rating > 5) {
            return Array(5).fill(<span className="text-muted">★</span>);
        }

        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {Array(fullStars).fill(<span className="text-warning">★</span>)}
                {halfStar && <span className="text-warning">★</span>}
                {Array(emptyStars).fill(<span className="text-light">★</span>)}
            </>
        );
    };


    const renderBusinessCards = (businessData) => {
        if (!businessData) return null;

        const allBusinesses = Object.values(businessData).flatMap(category => category.businesses);

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                slidesToSlide: 1
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
                slidesToSlide: 1
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1
            }
        };

        return (
            <div className="business-results">
                <Carousel key={`carousel-999`} responsive={responsive} partialVisible={false} infinite={false}>
                    {allBusinesses.map((business, idx) => (
                        <div key={idx} className="card business-card h-100">
                            <div className="card-body">
                                <h5 className="card-title">{business.businessName}</h5>
                                <p className="card-text rating">
                                    <span className="stars">{renderStars(business.googlePlaces?.rating)}</span>
                                    <span className="rating-text">({business.googlePlaces?.rating || 'N/A'}) - {business.googlePlaces?.userRatingCount || '0'} Reviews</span>
                                </p>
                                <div className="card-text services-list">
                                    <strong>Services:</strong>
                                    <ul>
                                        {business.services.slice(0, 3).map((service, idx) => (
                                            <li key={idx}>{service.serviceName}</li>
                                        ))}
                                        {business.services.length > 3 && (
                                            <li>+ {business.services.length - 3} more</li>
                                        )}
                                    </ul>
                                </div>
                                <div className="card-text contact">
                                    <span>📞 {business.googlePlaces?.internationalPhoneNumber || 'Phone not available'}</span>
                                    <span>🌐 <a href={business.websiteUrl} target="_blank" rel="noopener noreferrer">Website</a></span>
                                </div>
                                <a
                                    href={`/${city}/business/${createSlug(business.businessName)}`}
                                    className="btn-business btn-primary-business mt-3"
                                    role="button"
                                >
                                    View Business
                                </a>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        );
    };

    return (
        <>
            <div className={`chatbot-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
                <div className="chatbot-messages">
                    {messages.map((message, index) => (
                        <div key={index} className="chatbot-message-container">
                            {message.role === 'assistant' && (
                                <div className="chatbot-assistant-avatar">
                                    <div className="glowing-indicator"></div>
                                    <span className="bot-name">Lisa</span>
                                </div>
                            )}

                            {message.role === 'mock' ? (
                                message.content
                            ) : (
                                // Default rendering for assistant or user messages
                                <div key={index} className={`message ${message.role}`}>
                                    <div className="typing-indicator">
                                        {typeof message.content === 'string' ? (
                                            <ReactMarkdown>{message.content}</ReactMarkdown>
                                        ) : (
                                            message.content
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                    ))}
                    {isWaitingForResponse && (
                        <div key={'placeholderIndex'} className="chatbot-message-container">
                            <div className="chatbot-assistant-avatar">
                                <div className="glowing-indicator"></div>
                                <span className="bot-name">Lisa</span>
                            </div>
                            <div key={'waitingIndex'} className={`message assistant`}>
                                <div className="typing-indicator-waiting">
                                    <div className="typing-dot"></div>
                                    <div className="typing-dot"></div>
                                    <div className="typing-dot"></div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>


                {/* Quick Replies */}
                {quickReplies.length > 0 && (
                    <div className="quick-replies">
                        {quickReplies.map((reply, index) => (
                            <button key={index} onClick={() => handleQuickReply(reply)}>
                                {reply}
                            </button>
                        ))}
                    </div>
                )}

                <div className="chatbot-input">
                    <input
                        type="text"
                        value={userInput}
                        onChange={handleUserInput}
                        onKeyPress={handleKeyPress} // Handle pressing Enter
                        placeholder="Type your message..."
                    />
                    <button onClick={() => handleSendMessage(userInput)}>Send</button>
                </div>
            </div>
            {/* {renderSmallBar()} */}
        </>

    );
};

export default Chatbot;
