import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './ServiceGroupSubcategoryPage.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function capitalizeWords(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

const reverseSlug = (slug) => {
    return slug.replace(/-/g, ' ');
};

const createSlug = (businessName) => {
    return businessName.toLowerCase().replace(/\s+/g, '-');
};

const ServiceGroupSubcategoryPage = () => {
    const { city, serviceGroup, serviceGroupSubcategory } = useParams();
    const cityDisplayName = capitalizeWords(reverseSlug(city));
    const serviceGroupDisplayName = capitalizeWords(reverseSlug(serviceGroup));
    const subcategoryDisplayName = capitalizeWords(reverseSlug(serviceGroupSubcategory));

    const [businesses, setBusinesses] = useState([]);
    const [topBusinesses, setTopBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [subcategoryDescription, setSubcategoryDescription] = useState('');

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch(`https://zxjxolehed.execute-api.ap-southeast-1.amazonaws.com/dev/data?cityName=${encodeURIComponent(city)}&serviceSlug=${encodeURIComponent(serviceGroup)}&serviceGroupSubcategory=${encodeURIComponent(serviceGroupSubcategory)}`);
                const data = await response.json();

                console.log(data);

                let subcategoryName = Object.keys(data.subcategories)[0];

                setSubcategoryDescription(data.subcategories[subcategoryName].description);
                setBusinesses(data.subcategories[subcategoryName].businesses.slice(2) || []);
                setTopBusinesses(data.subcategories[subcategoryName].businesses.slice(0, 3)); // Top 3 businesses
                setLoading(false);
            } catch (error) {
                console.error('Error fetching subcategories:', error);
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, [city, serviceGroup, serviceGroupSubcategory]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const renderStars = (rating) => {
        if (typeof rating !== 'number' || rating < 0 || rating > 5) {
            return Array(5).fill(<span className="text-muted">★</span>);
        }

        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {Array(fullStars).fill(<span className="text-warning">★</span>)}
                {halfStar && <span className="text-warning">★</span>}
                {Array(emptyStars).fill(<span className="text-light">★</span>)}
            </>
        );
    };

    return (
        <div className="service-group-subcategory-container">
            <Helmet>
                <title>{`Top ${subcategoryDisplayName} Services in ${cityDisplayName} - ${serviceGroupDisplayName} - BioHack Hive`}</title>
                <meta
                    name="description"
                    content={`Find the best ${subcategoryDisplayName} services in ${cityDisplayName} under ${serviceGroupDisplayName}. Explore top providers, read reviews, and choose the best service to meet your needs.`}
                />
            </Helmet>

            {/* Breadcrumbs */}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={`/${city}/services`}>Services</Link></li>
                    <li className="breadcrumb-item"><Link to={`/${city}/service/${serviceGroup}`}>{serviceGroupDisplayName}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{subcategoryDisplayName}</li>
                </ol>
            </nav>

            {/* Title and Description */}
            <h1 className="mb-4">
                Best {subcategoryDisplayName === 'General' ? `${subcategoryDisplayName} ${serviceGroupDisplayName}` : subcategoryDisplayName} in {cityDisplayName}
            </h1>
            <div className="service-subcategory-description mb-4">
                <p>{subcategoryDescription}</p>
                <p>
                    Explore top-rated {subcategoryDisplayName === 'General' ? `${subcategoryDisplayName} ${serviceGroupDisplayName}` : subcategoryDisplayName} services in {cityDisplayName}. From expert providers to customer favorites, find the best service that suits your needs.
                </p>
            </div>

            {/* Fallback when no businesses are found */}
            {businesses.length === 0 && topBusinesses.length === 0 ? (
                <div className="no-businesses-found">
                    <h3 className="no-services-header">
                        No {subcategoryDisplayName} services found in {cityDisplayName}.
                    </h3>
                    <p className="no-services-text">
                        It looks like there are no businesses offering {subcategoryDisplayName} services in {cityDisplayName} at the moment. But don’t worry, you can explore all available {serviceGroupDisplayName} services.
                    </p>
                    <Link to={`/${city}/service/${serviceGroup}`} className="fallback-link">
                        Explore All {serviceGroupDisplayName} Services in {cityDisplayName}
                    </Link>
                </div>
            ) : (
                <>
                    {/* Top Businesses */}
                    <div className="top-businesses-section business-section mb-5">
                        <h2 className="section-title">Top Businesses</h2>
                        <div className="business-list business-list-grid">
                            {topBusinesses.map((business, idx) => (
                                <div key={idx} className="card business-card h-100">
                                    <div className="card-body">
                                        <h3 className="card-title">{business.businessName}</h3>
                                        <p className="card-text rating">
                                            <span className="stars">{renderStars(business.googlePlaces?.rating)}</span>
                                            <span className="rating-text">({business.googlePlaces?.rating || 'N/A'}) - {business.googlePlaces?.userRatingCount || '0'} Reviews</span>
                                        </p>
                                        <div className="card-text services-list">
                                            <strong>Services:</strong>
                                            <ul>
                                                {business.services.slice(0, 3).map((service, idx) => (
                                                    <li key={idx}>{service.serviceName}</li>
                                                ))}
                                                {business.services.length > 3 && (
                                                    <li>+ {business.services.length - 3} more</li>
                                                )}
                                            </ul>
                                        </div>
                                        <p className="card-text address">{business.googlePlaces?.formattedAddress || 'Location not available'}</p>
                                        <div className="card-text contact">
                                            <span>📞 {business.googlePlaces?.internationalPhoneNumber || 'Phone not available'}</span>
                                            <span>🌐 <a href={business.websiteUrl} target="_blank" rel="noopener noreferrer">Website</a></span>
                                        </div>
                                        <a
                                            href={`/${city}/business/${createSlug(business.businessName)}`}
                                            className="btn-business btn-primary-business mt-3"
                                            role="button"
                                        >
                                            View Business Details
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* All Businesses */}
                    <div className="all-businesses-section business-section">
                        <h2 className="section-title">All Businesses</h2>
                        <div className="business-list business-list-grid">
                            {businesses.map((business, idx) => (
                                <div key={idx} className="card business-card h-100">
                                    <div className="card-body">
                                        <h3 className="card-title">{business.businessName}</h3>
                                        <p className="card-text rating">
                                            <span className="stars">{renderStars(business.googlePlaces?.rating)}</span>
                                            <span className="rating-text">({business.googlePlaces?.rating || 'N/A'}) - {business.googlePlaces?.userRatingCount || '0'} Reviews</span>
                                        </p>
                                        <div className="card-text services-list">
                                            <strong>Services:</strong>
                                            <ul>
                                                {business.services.slice(0, 3).map((service, idx) => (
                                                    <li key={idx}>{service.serviceName}</li>
                                                ))}
                                                {business.services.length > 3 && (
                                                    <li>+ {business.services.length - 3} more</li>
                                                )}
                                            </ul>
                                        </div>
                                        <p className="card-text address">{business.googlePlaces?.formattedAddress || 'Location not available'}</p>
                                        <div className="card-text contact">
                                            <span>📞 {business.googlePlaces?.internationalPhoneNumber || 'Phone not available'}</span>
                                            <span>🌐 <a href={business.websiteUrl} target="_blank" rel="noopener noreferrer">Website</a></span>
                                        </div>
                                        <a
                                            href={`/${city}/business/${createSlug(business.businessName)}`}
                                            className="btn-business btn-primary-business mt-3"
                                            role="button"
                                        >
                                            View Business Details
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ServiceGroupSubcategoryPage;
