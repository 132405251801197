/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_content_delivery_bucket": "biohackhive-20240905170339-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d1cx31c1uu2fe3.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://a4xl7f3xbfcs3kbqdzgvhhjoxu.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-25lii6emtzcy7or7x4rxgrg7ui",
    "aws_cloud_logic_custom": [
        {
            "name": "BiohackData",
            "endpoint": "https://zxjxolehed.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "DevAPI2",
            "endpoint": "https://3otz5feh7e.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
