// SmallChatBar.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import './Chatbot.css';
import { ChatBotContext } from './ChatBotContext';

const SmallChatBar = () => {
    const { setIsExpanded } = useContext(ChatBotContext);

    const smallBarMessages = [
        "Hi! I'm here to help you find the perfect wellness service.",
        "Looking for something specific or just exploring options?",
        "How can I assist you on your wellness journey today?",
        "Need recommendations? I'm happy to guide you!",
        "Let's find the right service to fit your wellness goals."
    ];

    const [messageIndex, setMessageIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const wordIndexRef = useRef(0);
    const currentSentenceRef = useRef('');

    useEffect(() => {
        const currentMessage = smallBarMessages[messageIndex];
        const words = currentMessage.split(' ');

        setDisplayedText('');
        wordIndexRef.current = 0;
        currentSentenceRef.current = '';

        const typingInterval = setInterval(() => {
            if (wordIndexRef.current < words.length) {
                currentSentenceRef.current += words[wordIndexRef.current] + ' ';
                setDisplayedText(currentSentenceRef.current);
                wordIndexRef.current++;
            } else {
                clearInterval(typingInterval);
                setTimeout(() => {
                    setMessageIndex((prevIndex) => (prevIndex + 1) % smallBarMessages.length);
                }, 10000);
            }
        }, 80);

        return () => clearInterval(typingInterval);
    }, [messageIndex]);

    return (
        <div className="chatbot-small-bar" onClick={() => setIsExpanded(true)}>
            <div className="chatbot-message">
                <div className="glowing-indicator"></div>
                <span className="bot-name">Lisa</span>
                <div className="typing-indicator">
                    <span>{displayedText}</span>
                </div>
            </div>
            <button className="chat-now-btn">Chat Now</button>
        </div>
    );
};

export default SmallChatBar;
