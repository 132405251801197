import React, { useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import ViewToggle from './ViewToggle';
import SmallChatBar from './SmallChatBar';
import ChatBoxWrapper from './ChatBoxWrapper'; // Import ChatboxWrapper
import './Layout.css';

function capitalizeWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

const createSlug = (businessName) => {
  return businessName.toLowerCase().replace(/\s+/g, '-');
};

const reverseSlug = (slug) => {
  return slug.replace(/-/g, ' ');
};

const Layout = () => {
  const { city } = useParams(); // Get the city name from the route
  const location = useLocation();
  const [isChatExpanded, setIsChatExpanded] = useState(false); // State to control chat expansion

  // Get the encoded city name directly from the URL
  const encodedCity = encodeURIComponent(decodeURIComponent(city));

  const shouldShowHero = location.pathname.startsWith(`/${encodedCity}`);

  const cityDisplayName = capitalizeWords(reverseSlug(city));

  return (
    <>
      {shouldShowHero && (
        <>
          <div className="hero-banner">
            <div className="hero-content">
              <h1>Health & Wellness Services in {cityDisplayName}</h1>
              <p>Explore the best wellness options for your mind and body.</p>
              {/* <button className="browse-btn" onClick={() => window.location.href = `/${encodedCity}/service`}>
                Browse Directory
              </button> */}
            </div>
          </div>


          {/* Chat Section outside the hero, just below it */}
          <div className="chat-section">
            <div className="chat-header">
              <h2>Meet Lisa, Your AI Wellness Concierge</h2>
              <p>Need help finding the perfect wellness service? Lisa’s AI-powered recommendations make it quick and easy. Whether you know exactly what you need or want to explore options, Lisa’s here to guide you 24/7.</p>
            </div>
            <div className="chat-container">
              <SmallChatBar />
            </div>
          </div>


          {/* ViewToggle placed below the chat section */}
          <ViewToggle />
        </>
      )}
      <ChatBoxWrapper />
      <Outlet />
    </>
  );
};

export default Layout;
