// src/contexts/GoogleMapsContext.js

import React, { createContext, useState, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';

export const GoogleMapsContext = createContext();

export const GoogleMapsProvider = ({ children }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAq6YpYgeL-rFzQ-tj6_xIPKyKRVraGUlI"
            libraries={['places']}
            onLoad={() => setIsLoaded(true)}
        >
            <GoogleMapsContext.Provider value={{ isLoaded }}>
                {children}
            </GoogleMapsContext.Provider>
        </LoadScript>
    );
};
