import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import './ViewToggle.css';

const ViewToggle = () => {
    const navigate = useNavigate();
    const { city } = useParams(); // Extract the city name from the route
    const location = useLocation(); // Get the current location

    // Determine the initial view based on the current route
    const getInitialView = () => {
        if (location.pathname.includes('/services')) {
            return 'service';
        } else if (location.pathname.includes('/businesses')) {
            return 'business';
        } else if (location.pathname.includes('/categories')) {
            return 'category';
        }
        return 'service'; // Default to 'service' if none matches
    };

    const [view, setView] = useState(getInitialView);

    useEffect(() => {
        // Update the view if the location changes
        setView(getInitialView());
    }, [location]);

    const handleToggle = (selectedView) => {
        setView(selectedView);
        if (selectedView === 'service') {
            navigate(`/${city}/services`);
        } else if (selectedView === 'business') {
            navigate(`/${city}/businesses`);
        } else if (selectedView === 'category') {
            navigate(`/${city}/categories`); // New route for categories
        }
    };

    return (
        <div className="view-toggle">
            <span className="view-toggle-label">View by:</span>
            <button
                className={view === 'service' ? 'active' : ''}
                onClick={() => handleToggle('service')}
            >
                Service
            </button>
            <button
                className={view === 'business' ? 'active' : ''}
                onClick={() => handleToggle('business')}
            >
                Business
            </button>
            {/* <button
                className={view === 'category' ? 'active' : ''}
                onClick={() => handleToggle('category')}
            >
                Category
            </button> */}
        </div>
    );
};

export default ViewToggle;
