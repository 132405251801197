import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GoogleMapsContext } from '../contexts/GoogleMapsContext';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { Helmet } from 'react-helmet-async';
import './BusinessView.css';

const reverseSlug = (slug) => {
    return slug.replace(/-/g, ' ');
};

function capitalizeWords(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

const BusinessView = () => {
    const { isLoaded } = useContext(GoogleMapsContext);
    const { businessSlug, city } = useParams();
    const businessName = capitalizeWords(reverseSlug(businessSlug));

    const [business, setBusiness] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: -8.749324339537052, lng: 115.18559053178676 });
    const [mapZoom, setMapZoom] = useState(12);
    const mapRef = useRef(null);

    const [activeTab, setActiveTab] = useState('All Services');

    useEffect(() => {
        const fetchBusiness = async () => {
            try {
                const response = await fetch(`https://zxjxolehed.execute-api.ap-southeast-1.amazonaws.com/dev/data?businessName=${encodeURIComponent(businessSlug)}&cityName=${encodeURIComponent(city)}`);
                const data = await response.json();
                data.businessName = capitalizeWords(data.businessName);
                setBusiness(data);

                if (data?.googlePlaces?.location) {
                    setMapCenter({
                        lat: data.googlePlaces.location.latitude,
                        lng: data.googlePlaces.location.longitude,
                    });
                }
            } catch (error) {
                console.error('Error fetching business:', error);
            }
        };

        fetchBusiness();
    }, [businessSlug]);

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
        borderRadius: '8px',
        marginBottom: '20px',
    };

    const handleMarkerClick = () => {
        if (selectedMarker) {
            setSelectedMarker(null);
        } else {
            setSelectedMarker(business);
            if (mapRef.current) {
                mapRef.current.panTo(mapCenter);
                setMapCenter(mapCenter);
            }
        }
    };

    const handleMapLoad = (map) => {
        mapRef.current = map;
        map.panTo(mapCenter);
        setMapCenter(mapCenter);
        setMapZoom(12);
    };

    const renderStars = (rating) => {
        if (typeof rating !== 'number' || rating < 0 || rating > 5) {
            return Array(5).fill(<span className="text-muted">★</span>);
        }

        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {Array(fullStars).fill(<span className="text-warning">★</span>)}
                {halfStar && <span className="text-warning">★</span>}
                {Array(emptyStars).fill(<span className="text-light">★</span>)}
            </>
        );
    };

    const generateJSONLD = () => {
        const structuredData = {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": business?.businessName,
            "image": business?.image || "https://www.example.com/images/logo.png",
            "@id": `https://www.example.com/${encodeURIComponent(businessName)}`,
            "url": business?.websiteURL,
            "telephone": business?.googlePlaces?.internationalPhoneNumber || "N/A",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": business?.googlePlaces?.formattedAddress || "Address not available",
                "addressLocality": business?.googlePlaces?.addressComponents.find(component => component.types.includes('locality'))?.longText || "N/A",
                "addressRegion": business?.googlePlaces?.addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.longText || "N/A",
                "postalCode": business?.googlePlaces?.addressComponents.find(component => component.types.includes('postal_code'))?.longText || "N/A",
                "addressCountry": business?.googlePlaces?.addressComponents.find(component => component.types.includes('country'))?.longText || "ID"
            },
            "aggregateRating": business?.googlePlaces?.rating && business?.googlePlaces?.userRatingCount ? {
                "@type": "AggregateRating",
                "ratingValue": business.googlePlaces.rating.toString(),
                "reviewCount": business.googlePlaces.userRatingCount.toString()
            } : undefined,
            "priceRange": "$$",
            "servesCuisine": "International",
        };
        return JSON.stringify(structuredData, null, 2);
    };

    useEffect(() => {
        if (business) {
            const script = document.createElement('script');
            script.type = 'application/ld+json';
            script.text = generateJSONLD();
            document.head.appendChild(script);

            return () => {
                document.head.removeChild(script);
            };
        }
    }, [business]);

    if (!business || !business.googlePlaces || !business.services) {
        return <div>Loading...</div>;
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const flattenServices = () => {
        let flattened = [];
        for (const group in business.services) {
            for (const subcategory in business.services[group]) {
                flattened = flattened.concat(business.services[group][subcategory].map(service => ({
                    ...service,
                    group,
                    subcategory
                })));
            }
        }
        return flattened;
    };

    const filteredServices = activeTab === 'All Services'
        ? flattenServices()
        : flattenServices().filter(service => service.group === activeTab);

    return (
        <div className="business-view-container">
            <Helmet>
                <title>{`${business.businessName} - BioHack Hive`}</title>
                <meta
                    name="description"
                    content={`${business.businessName} offers services such as ${Object.keys(business.services).map(group => `${group} (${Object.keys(business.services[group]).join(', ')})`).join(', ')} in ${city}. Check out their ratings, reviews, and more.`}
                />
            </Helmet>
            <h1 className="mb-4">{business.businessName}</h1>

            <div className="business-details mb-4">
                <p className="lead">{business.description || 'No description available.'}</p>
                <p className="rating">
                    <span className="stars">{renderStars(business.googlePlaces.rating)}</span>
                    ({business.googlePlaces.rating || 'N/A'}) - {business.googlePlaces.userRatingCount || 'No'} Reviews
                </p>
                <p className="contact-info">
                    📞 {business.googlePlaces.internationalPhoneNumber || 'Phone number not available'}
                    <br />
                    🌐 <a href={business.websiteUrl} target="_blank" rel="noopener noreferrer">Visit Website</a>
                </p>
                <p className="address">{business.googlePlaces.formattedAddress}</p>
            </div>

            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={mapCenter}
                    zoom={mapZoom}
                    onLoad={handleMapLoad}
                >
                    <MarkerF
                        position={mapCenter}
                        title={business.businessName}
                        onClick={handleMarkerClick}
                    />
                    {selectedMarker && (
                        <InfoWindowF
                            position={mapCenter}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div>
                                <h4>{selectedMarker.businessName}</h4>
                                <p>★★★★☆ ({selectedMarker.googlePlaces.rating}) - {selectedMarker.googlePlaces.userRatingCount} Reviews</p>
                                <p><strong>Services:</strong></p>
                                <ul>
                                    {selectedMarker.services.slice(0, 3).map((service, idx) => (
                                        <li key={idx}>{service.name}</li>
                                    ))}
                                    {selectedMarker.services.length > 3 && (
                                        <li>+ {selectedMarker.services.length - 3} more</li>
                                    )}
                                </ul>
                                <p><strong>Address:</strong> {selectedMarker.googlePlaces.formattedAddress}</p>
                                <p>📞 {selectedMarker.googlePlaces.internationalPhoneNumber || 'Phone number not available'}</p>
                                <p>🌐 <a href={selectedMarker.websiteURL} target="_blank" rel="noopener noreferrer">Visit Website</a></p>
                            </div>
                        </InfoWindowF>
                    )}
                </GoogleMap>
            )}

            <h3 className="mt-5">Services Offered</h3>

            <div className="services-tabs">
                <button
                    className={`tab-button ${activeTab === 'All Services' ? 'active' : ''}`}
                    onClick={() => handleTabClick('All Services')}
                >
                    All Services
                </button>
                {Object.keys(business.services).map((group, index) => (
                    <button
                        key={index}
                        className={`tab-button ${activeTab === group ? 'active' : ''}`}
                        onClick={() => handleTabClick(group)}
                    >
                        {group}
                    </button>
                ))}
            </div>

            <div className="row">
                {filteredServices.map((service, idx) => (
                    <div key={idx} className="col-md-4 mb-4">
                        <div className="card list-group-item">
                            <div className="card-body">
                                <h5 className="card-title">{service.serviceName}</h5>
                                <p className="card-text">{service.description || 'No description available.'}</p>
                                <p className="card-text"><strong>Price:</strong> {service.price || 'Price not available'}</p>
                                <span className="badge badge-secondary mt-3">{service.group}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BusinessView;
