import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import './CitySearchModal.css';

const createSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-');
};

function capitalizeWords(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}

const CitySearchModal = () => {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const cityName = pathParts[1];
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasSearched, setHasSearched] = useState(false); // New state to track if a search has occurred

    // Function to fetch search results from the API
    const fetchResults = async (searchQuery) => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(
                `https://zxjxolehed.execute-api.ap-southeast-1.amazonaws.com/dev/data?data=search&q=${encodeURIComponent(searchQuery)}&cityName=${createSlug(cityName)}`
            );
            if (response.ok) {
                const data = await response.json();
                setResults(data);
                setHasSearched(true); // Set hasSearched to true after search is completed
            } else {
                console.error('Error fetching search results:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            setLoading(false); // End loading
        }
    };

    // Debounced function wrapped in useCallback
    const debouncedFetchResults = useCallback(
        debounce((searchQuery) => {
            if (searchQuery) {
                fetchResults(searchQuery);
            } else {
                setResults([]);
                setHasSearched(false); // Reset hasSearched if the query is cleared
            }
        }, 500),
        [cityName] // Ensure debounce function is recreated if cityName changes
    );

    // Handle input change and trigger the debounced search
    const handleInputChange = (event) => {
        const value = event.target.value;
        setQuery(value);
        debouncedFetchResults(value);
    };

    // Function to determine the order of types based on first occurrence
    const getTypeOrder = () => {
        const typeOrder = [];
        const typeMap = {};

        results.forEach((result) => {
            if (!typeMap[result.type]) {
                typeMap[result.type] = true;
                typeOrder.push(result.type);
            }
        });

        return typeOrder;
    };

    const typeOrder = getTypeOrder();

    return (
        <div className="search-container">
            <input
                type="text"
                placeholder="Search for a city, service, or business..."
                className="form-control search-box"
                value={query}
                onChange={handleInputChange}
            />
            {loading && <div className="spinner"></div>}
            {(results.length > 0 || (hasSearched && !loading && query)) && (
                <div className="search-results-container">
                    {results.length > 0 ? (
                        typeOrder.map((type) => (
                            <div key={type} className="search-results-section">
                                <h5 className="search-results-header">{type.charAt(0).toUpperCase() + type.slice(1)}</h5>
                                <ul className="search-results-list">
                                    {results
                                        .filter(result => result.type === type)
                                        .map((result, index) => (
                                            <li key={index} className="search-result-item">
                                                {type === 'business' && (
                                                    <a
                                                        href={`/${createSlug(result.cityName)}/business/${createSlug(result.name)}`}
                                                        className="result-link"
                                                    >
                                                        {result.name}
                                                    </a>
                                                )}
                                                {type === 'city' && (
                                                    <a
                                                        href={`/${createSlug(result.name)}/services`}
                                                        className="result-link"
                                                    >
                                                        {capitalizeWords(result.name)}
                                                    </a>
                                                )}
                                                {type === 'service' && (
                                                    <a
                                                        href={`/${createSlug(result.cityName)}/service/${createSlug(result.name)}`}
                                                        className="result-link"
                                                    >
                                                        {result.name}
                                                    </a>
                                                )}
                                                {type === 'subcategory' && (
                                                    <a
                                                        href={`/${createSlug(result.cityName)}/service/${createSlug(result.serviceGroup)}/${createSlug(result.name)}`}
                                                        className="result-link"
                                                    >
                                                        {result.name}
                                                        <span className="service-group-tag">({result.serviceGroup})</span>
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        ))
                    ) : (
                        hasSearched && !loading && (
                            <div className="no-results">
                                No results found for "{query}"
                            </div>
                        )
                    )}
                </div>
            )}
        </div>
    );
};

export default CitySearchModal;
